<template>
  <fieldset>
    <legend>Documentos</legend>

    <template v-if="viatico.documentos.length > 0">
    <div v-for="documento in archivos_paginados" :key="documento.id" class="documento">
      <div class="archivo" @click="descargar_archivo(documento)" title="Descargar documento">{{ (documento.num_item_pag ? '#'+documento.num_item_pag : '')+' - '+documento.nombre_original }} <i class="fa-solid fa-download"></i></div>
      <div v-if="viatico.estatus != 3" class="opciones"><i class="fa-solid fa-trash" title="Eliminar archivo" @click="eliminar_documento(documento)"></i></div>
    </div>

    <div v-if="viatico.documentos.length > por_pagina" class="row mt-3">
      <div class="col-sm-12 text-right paginacion">
        <div class="anterior" @click="pagina_anterior">Anterior</div>
        <div v-for="pagina in $paginator.paginate_navigation(viatico.documentos, pagina_actual,por_pagina, 3)" :key="pagina" :class="(pagina_actual == pagina) ? 'pagina activo' : 'pagina'" @click="pagina_actual=pagina">{{ pagina }}</div>
        <div class="siguiente" @click="pagina_siguiente">Siguiente</div>

      </div>
    </div>
    </template>

    <div v-else >Sin documentos</div>

    <div v-if="viatico.estatus != 3" class="subir text-right my-3">
      <button class="btn btn-secondary" @click="$refs.subir_documento.click()">Subir documento</button>
      <input type="file" name="subir_documento" id="subir_documento" ref="subir_documento" @change="subir_documento"></input>
    </div>
  </fieldset>
</template>

<script>
import api from '@/apps/constelacion/api/tesoreria';

export default {
  props: {
    viatico: {
      type: Object,
      default() {
        return {
          id: null,
          documentos:[]
        }
      }
    }
  }
  ,data() {
    return  {
      pagina_actual: 1,
      por_pagina: 10,

    }
  }
  ,methods: {
    async subir_documento() {
      try {
        if (this.$refs.subir_documento.files.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar un documento','error','alert');

        let payload = new FormData();
        payload.append("archivos[]", this.$refs.subir_documento.files[0]);

        let res = (await api.subir_documento(this.viatico.id, payload)).data;
        this.$log.info('res', res);

        this.$refs.subir_documento.value = null;

        let continuar = true;
        let error = '<p>Se encontraron los siguientes errores:</p><ul>';

        res.forEach(archivo => {
          if (!archivo.success) {
            continuar = false;
            error += `<li>Archivo ${archivo.archivo}: ${archivo.error}</li>`;
          }
        })

        error += '</ul>';
        
        if (!continuar)
          return this.$helper.showMessage('Error',error,'error','alert');

        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,async descargar_archivo(documento) {
      try {
        this.$log.info('descargar', documento);
        let res = (await api.descargar_documento(this.viatico.id, documento.id)).data;
        this.$helper.descargar_documento(res, documento.nombre_original);
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,async eliminar_documento(documento) {
      try {
        this.$log.info('eliminar', documento);
        let res = (await api.eliminar_documento(this.viatico.id, documento.id)).data;
        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,pagina_anterior() {
      if (this.pagina_actual > 1)
        this.pagina_actual -= 1;
    }
    ,pagina_siguiente() {
      // this.$log.info('pagina_siguiente',this.pagina_siguiente)
      if (this.pagina_actual < this.$paginator.total_pages(this.viatico.documentos, this.por_pagina))
        this.pagina_actual += 1;
    }
  }
  ,computed: {
    archivos_paginados() {
      return this.$paginator.paginate_data(this.viatico.documentos, this.pagina_actual, this.por_pagina);
    }
  }
}
</script>

<style lang="scss" scoped>
.documento {
  padding: 10px 5px;
  .archivo, .opciones {
    display: inline-block;

    img {
      width: 15px;
    }
  }
  .archivo {
    width: 85%;
    cursor: pointer;
  }
  .opciones {
    width: 15%;
    text-align: right;
  }
}

.documento:nth-child(2n+1) {
  background-color: #f5f5f5;
}

input[type="file"] {
  display: none;
}

.paginacion {
    margin-bottom: 10px;

    .anterior, .siguiente, .pagina {
      color: #979797;
      display: inline-block;
      padding: 3px 8px;
      cursor: pointer;
    }

    .anterior, .siguiente {
      min-width: 50px;
      border: solid 1px #e0e0e0;
      border-radius: 3px;
    }

    .anterior:hover, .siguiente:hover, .pagina:hover {
      color: #493cbe;
    }

    .anterior {
      border-right: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .siguiente {
      border-left: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .pagina {
      border: solid 1px #e0e0e0;
    }

    .activo {
      background-color: #6159aa;
      color: #fff;
    }
  }
</style>